<template>
  <div class="projectFilters flex flex-col gap-2">
    <div
      class="flex items-center pb-1 rounded-md"
      :class="{ 'mb-3': !showFilters }"
    >
      <InputField
        id="project_search_input"
        class="w-full mr-4 tabletPortrait:mr-6"
        prefix-icon="fa-magnifying-glass"
        :placeholder="placeholder"
        @inputChange="({ value }) => (search = value)"
      />
      <a
        data-intercom-target="Filter"
        data-cy="filters_toggle"
        class="group/filterIcon w-fit min-w-fit block ml-auto py-2.5 px-0 text-neutral-200 hover:cursor-pointer hover:text-primary-100"
        @click="toggleFilters"
      >
        <font-awesome-icon icon="fa-filter" />

        {{ $t('shared.button.filters') }}
      </a>
    </div>
    <div
      class="hidden flex-col items-end gap-2.5 p-0 transition-all duration-[600ms] ease-[cubic-bezier(0.33,_1,_0.68,_1)] tabletPortrait:flex-row"
      :class="{
        '!flex mb-3': showFilters
      }"
    >
      <div
        class="w-full mb-0 grid grid-cols-1 grid-rows-4 gap-y-2.5 tabletPortrait:grid-cols-6 tabletPortrait:grid-rows-1 tabletPortrait:gap-y-0 tabletPortrait:gap-x-2.5"
      >
        <Select
          v-for="filter in projectFilters"
          :key="filter.type"
          :title="$t(`customer.project_filters.${filter.type}`)"
        >
          <SelectSelectionCustom
            :data-cy="filter.title"
            :allowSearch="filter.data.length > 10"
            :placeholder="$t(`customer.project_filters.${filter.type}`)"
            :value="selectedFilter[filter.type] || ''"
            @search="handleFiltersSearch($event, filter.type)"
          >
            <SelectOptionCustom
              v-if="filteredData(filter.data, filter.type).length === 0"
              disabled
              >{{ $t('shared.label.no_options') }}</SelectOptionCustom
            >
            <SelectOptionCustom
              v-for="(item, i) in filteredData(filter.data, filter.type)"
              :key="`${item.name}_${i}`"
              :value="item.value"
              :selected="selectedFilter[filter.type] === item.name"
              @inputChange="onFilterProjects($event, filter.type, item.name)"
            >
              <span> {{ item.name }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
      </div>
      <BtnSecondary
        :title="$t('shared.button.clear')"
        class="bg-white py-2.5 px-4 mb-0.25"
        neutral
        @click="removeFilters"
      />
    </div>
  </div>
</template>
<script setup>
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import InputField from '@/components/shared/input/InputField'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import i18n from '@/i18n'
import _ from 'underscore'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const $t = i18n.t

const store = useStore()
const route = useRoute()

const search = ref(null)
const showFilters = ref(false)
const filterSearch = ref({
  customer_id: null,
  status: null,
  agent: null,
  created_date: null,
  preferred_deadline: null,
  account_workflow_id: null
})
const selectedFilter = ref({
  customer_id: null,
  status: null,
  agent: null,
  created_date: null,
  preferred_deadline: null,
  account_workflow_id: null
})

const pages = computed(() => store.state.workspace.pages)
const projectFilters = computed(() => store.state.workspace.projectFilters)
const folderId = computed(() => store.state.workspace.folderId)

const placeholder = computed(() =>
  route.name === 'clientProjects'
    ? $t('customer.placeholder.search_project')
    : $t('shared.placeholder.search_task')
)

watch(
  () => search.value,
  (searchInput) => {
    handleSearch(searchInput)
  }
)

const handleSearch = _.debounce(async function (searchInput) {
  startLoader({ shy: true })
  if (!searchInput) {
    await getProjects({
      search: null,
      currentPage: 1,
      perPage: pages.value.perPage,
      folderId: folderId.value
    })
  } else {
    await getProjects({
      search: search.value,
      currentPage: 1,
      perPage: pages.value.perPage,
      folderId: folderId.value
    })
  }
  stopLoader()
}, 1000)

function handleFiltersSearch(value, type) {
  filterSearch.value[type] = value
}

onMounted(async () => {
  await getProjectFilters()
  if (route.query.search) {
    search.value = route.query.search
  }
})

const stopLoader = () => store.dispatch('loading/stopLoader')
const startLoader = (payload) => store.dispatch('loading/startLoader', payload)
const getProjects = (payload) =>
  store.dispatch('workspace/getProjects', payload)
const getProjectFilters = () => store.dispatch('workspace/getProjectFilters')
const updateProjectFilter = (payload) =>
  store.commit('workspace/updateProjectFilter', payload)

async function onFilterProjects(value, type, item) {
  selectedFilter.value[type] = item
  await updateProjectFilter({ value, type })
  await getProjects({
    search: search.value,
    currentPage: 1,
    perPage: pages.value.perPage,
    folderId: folderId.value
  })
}
function toggleFilters() {
  showFilters.value = !showFilters.value
}
function filteredData(data, type) {
  return data
    .filter(
      (d) =>
        !filterSearch.value[type] ||
        d.name.toLowerCase().includes(filterSearch.value[type].toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name))
}

async function removeFilters() {
  search.value = null
  selectedFilter.value = {
    customer_id: null,
    status: null,
    agent: null,
    created_date: null,
    preferred_deadline: null,
    account_workflow_id: null
  }
  await getProjectFilters()
  await getProjects({
    search: null,
    currentPage: 1,
    perPage: pages.value.perPage,
    folderId: folderId.value
  })
}
</script>
