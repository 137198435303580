<template>
  <div class="mt-auto bg-neutral-80 w-full p-3 rounded">
    <div
      class="text-black flex flex-col gap-2 tabletPortrait:gap-6 tabletPortrait:flex-row"
    >
      <div
        class="flex flex-col gap-2 tabletPortrait:gap-6 tabletPortrait:flex-row"
      >
        <PaginationPages
          :pages="pages"
          @onPageChange="emit('onChange', $event)"
        />
        <div class="flex gap-2 items-center">
          <TextSmall>{{ $t('shared.pagination.page_text') }}</TextSmall>
          <Select>
            <SelectSelectionCustom
              data-cy="page_select"
              sm
              allowMultiline
              :value="selectedPage"
            >
              <SelectOptionCustom
                v-for="page in pageRange"
                :key="page"
                :value="page"
                :selected="selectedPage == page"
                @inputChange="$emit('onChange', $event)"
              >
                <span>{{ page }}</span>
              </SelectOptionCustom>
            </SelectSelectionCustom>
          </Select>
        </div>
      </div>
      <div v-if="props.pages.perPage" class="flex gap-2 items-center">
        <TextSmall>{{ $t('shared.pagination.items_text') }}</TextSmall>
        <Select>
          <SelectSelectionCustom
            data-cy="per_page_select"
            sm
            allowMultiline
            :value="props.pages.perPage"
          >
            <SelectOptionCustom
              v-for="perPageOption in perPageOptions"
              :key="perPageOption"
              :value="perPageOption"
              :selected="props.pages.perPage == perPageOption"
              @inputChange="changePerPage"
            >
              <span>{{ perPageOption }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
      </div>
    </div>
  </div>
</template>
<script setup>
import TextSmall from '@/components/shared/font/text/TextSmall'
import PaginationPages from '@/components/shared/pagination/PaginationPages'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const $t = i18n.t

const emit = defineEmits(['onChange', 'changePerPage'])

const props = defineProps({
  pages: Object
})

const pageRange = ref([])
const perPageOptions = ref([15, 25, 50, 100])
const selectedPage = ref(1)

watch(
  () => props.pages.total,
  (newVal) => {
    pageRange.value = range(1, newVal)
  },
  { immediate: true }
)
watch(
  () => props.pages.current,
  () => {
    selectedPage.value = props.pages.current
  }
)
watch(
  () => route.query.page,
  (page) => {
    if (page) {
      selectedPage.value = page
    } else {
      selectedPage.value = props.pages.current
    }
  },
  { immediate: true }
)

function range(min, max) {
  return [...Array(max - min + 1).keys()].map((i) => i + min)
}

function changePerPage(value) {
  $cookies.set('perPage', value)
  emit('changePerPage', Number(value))
}
</script>
