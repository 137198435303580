<template>
  <TextSmall
    class="py-1 px-2 rounded-lg select-none"
    :class="{
      'cursor-pointer hover:bg-primary-50 hover:text-primary-100 hover:font-medium':
        !disabled,
      'cursor-default bg-primary-50 text-primary-100 font-medium': selected
    }"
    :lightNeutral="disabled"
    @click.prevent="handleClick"
  >
    <slot />
  </TextSmall>
</template>
<script setup>
import TextSmall from '@/components/shared/font/text/TextSmall'

const emit = defineEmits(['inputChange', 'shiftSelect'])
const props = defineProps({
  value: [String, Number, Object, Array],
  disabled: Boolean,
  selected: Boolean,
  multiselect: Boolean
})

function handleClick(event) {
  if (props.disabled || props.selected) {
    event.stopPropagation()
    return
  }

  if (event.shiftKey === true) {
    emit('shiftSelect', props.value)
  } else emit('inputChange', props.value)

  if (props.multiselect) {
    event.stopPropagation()
  }
}
</script>
